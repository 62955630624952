<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
      <v-layout class="d-block">
        <v-row>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="3" sm="12" class="my-auto pt-0">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="company_name"
                      :class="{ required: detail.primary }"
                      >Company Name
                    </label>
                  </v-col>
                  <v-col md="9" sm="12">
                    <v-autocomplete
                      :items="companySelect"
                      v-model.trim="detail.company_id"
                      dense.
                      filled
                      :rules="[
                        validateRules.required(detail.company_id, 'first name'),
                      ]"
                      :readonly="detail?.id > 0 ? true : false"
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      placeholder="Select Company"
                      item-text="display_name"
                      item-value="id"
                      class="width-100 new-height"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Company(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <TextValidateInput
                      v-if="false"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="company_rules()"
                      id="company-name"
                      placeholder="Company Name"
                      :validationField="{
                        url_type: 'contact',
                        filter_type: 'contact',
                        field: 'company_name',
                      }"
                      :class="{ required: !detail.company_name }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.company_name"
                      hideTopMargin
                    ></TextValidateInput>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 my-auto" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="company_type"
                    >
                      Contact Name</label
                    >
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <v-select
                      id="salutation"
                      v-model.trim="detail.title"
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      placeholder="Salutation"
                      solo
                      flat
                      color="cyan"
                    ></v-select>
                  </v-col>

                  <v-col class="pt-0" md="3" sm="12">
                    <!-- <v-text-field
                      id="first_name"
                      :class="{
                        'v-text-field--enclosed error--text ':
                          !detail.first_name,
                      }"
                      v-model.trim="detail.first_name"
                      :rules="[
                        validateRules.required(detail.first_name, 'first name'),
                        validateRules.minLength(
                          detail.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.first_name,
                          'first name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="First Name"
                      solo
                      flat
                      color="cyan"
                      class="required-field"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field> -->
                    <TextValidateInput
                      :disabled="pageLoading"
                      :hideTopMargin="true"
                      :loading="pageLoading"
                      id="first-name"
                      :rules="[
                        validateRules.required(detail.first_name, 'first name'),
                        validateRules.minLength(
                          detail.first_name,
                          'first name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.first_name,
                          'first name',
                          100
                        ),
                      ]"
                      placeholder="First Name"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact_person',
                        field: 'first_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.first_name"
                      v-on:keyup="appendDisplayName()"
                    ></TextValidateInput>
                  </v-col>
                  <v-col class="pt-0" md="3" sm="12">
                    <v-text-field
                      id="last_name"
                      v-model.trim="detail.last_name"
                      :rules="[
                        validateRules.required(detail.last_name, 'first name'),
                        validateRules.minLength(
                          detail.last_name,
                          'last name',
                          2
                        ),
                        validateRules.maxLength(
                          detail.last_name,
                          'last name',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-on:keyup="appendDisplayName()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="my-auto pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="display_name"
                      >Mobile Number</label
                    >
                  </v-col>
                  <v-col class="" md="4" sm="12">
                    <PhoneTextField
                      required
                      id="y-primary_phone"
                      v-model="detail.primary_phone"
                      :phoneKey="1"
                      label="Mobile Number"
                    >
                    </PhoneTextField>
                  </v-col>
                  <v-col class="text-end my-auto" md="1" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                    >
                      DID
                    </label>
                  </v-col>
                  <v-col class="" md="4" sm="12">
                    <PhoneTextField
                      id="y-did"
                      v-model="detail.did"
                      :phoneKey="2"
                      :label="'DID'"
                    >
                    </PhoneTextField>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="my-auto pt-0" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="display_name"
                      >Display Name</label
                    >
                  </v-col>
                  <v-col class="" md="4" sm="12">
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          detail.display_name,
                          'Display Name'
                        ),
                      ]"
                      id="display-name"
                      placeholder="Display Name"
                      :validationField="{
                        url_type: 'customer',
                        filter_type: 'contact_person',
                        field: 'display_name',
                      }"
                      :parent-id="detail.id"
                      :current-id="detail.id"
                      show-dropdown
                      v-model="detail.display_name"
                      hideTopMargin
                    ></TextValidateInput
                  ></v-col>

                  <v-col md="1" sm="12" class="text-end my-auto">
                    <label
                      class="font-size-16 font-weight-500 required"
                      for="display_name"
                      >Email
                    </label>
                  </v-col>

                  <v-col md="4" sm="12">
                    <v-text-field
                      id="email_id"
                      v-model.trim="detail.primary_email"
                      :rules="[
                        validateRules.validEmail(detail.primary_email, 'email'),
                        validateRules.required(
                          detail.primary_email,
                          'first name'
                        ),
                        validateRules.minLength(
                          detail.primary_email,
                          'Email',
                          2
                        ),
                        validateRules.maxLength(
                          detail.primary_email,
                          'Email',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Email"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field>

                    <!-- <v-text-field
                      id="y-primary_email"
                      v-model.trim="detail.primary_email"
                      :rules="[
                        validateRules.required(detail.primary_email, 'email'),
                        validateRules.validEmail(detail.primary_email, 'email'),
                        validateRules.minLength(
                          detail.primary_email,
                          'email',
                          2
                        ),
                        validateRules.maxLength(
                          detail.primary_email,
                          'email',
                          100
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Email"
                      solo
                      flat
                      color="cyan"
                    ></v-text-field
                  > --></v-col
                  >
                </v-row>
                <v-row>
                  <v-col class="my-auto" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                    >
                      Designation</label
                    >
                  </v-col>

                  <v-col md="4" sm="12">
                    <v-autocomplete
                      :items="designations"
                      v-model.trim="detail.designation"
                      dense.
                      filled
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      placeholder="Select Designation"
                      v-on:click:append-outer="manageDesignationDialog = true"
                      item-text="text"
                      item-value="text"
                      class="width-100 new-height"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>

                    <template>
                      <ManageCustomerdesignation
                        :dialog="manageDesignationDialog"
                        :e_designation="designations"
                        v-on:close-dialog="manageDesignationDialog = false"
                        v-on:get-e-designation="setOptions"
                      ></ManageCustomerdesignation> </template
                  ></v-col>
                </v-row>

                <v-row class="" v-if="false">
                  <v-col md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Desigantion</label
                    >
                  </v-col>

                  <v-col md="4" sm="12">
                    <v-autocomplete
                      :items="designations"
                      v-model.trim="detail.designation"
                      dense.
                      filled
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      placeholder="Select Designation"
                      v-on:click:append-outer="manageDesignationDialog = true"
                      item-text="text"
                      item-value="text"
                      class="width-100 new-height"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>

                    <template>
                      <ManageCustomerdesignation
                        :dialog="manageDesignationDialog"
                        :e_designation="designations"
                        v-on:close-dialog="manageDesignationDialog = false"
                        v-on:get-e-designation="setOptions"
                      ></ManageCustomerdesignation> </template
                  ></v-col>

                  <v-col class="text-end my-auto" md="1" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Department</label
                    >
                  </v-col>

                  <v-col md="4" sm="12">
                    <v-autocomplete
                      :items="departments"
                      v-model.trim="detail.department"
                      dense.
                      filled
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      placeholder="Select Department"
                      v-on:click:append-outer="manageDepartmentDialog = true"
                      item-text="text"
                      item-value="text"
                      class="width-100 new-height"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>

                    <template>
                      <ManageCompanydepartment
                        :dialog="manageDepartmentDialog"
                        :e_department="departments"
                        v-on:close-dialog="manageDepartmentDialog = false"
                        v-on:get-e-designation="setdepartment"
                      ></ManageCompanydepartment> </template
                  ></v-col>
                  <v-col class="" md="3" sm="12">
                    <label class="font-size-16 font-weight-500" for="website"
                      >Tags</label
                    >
                  </v-col>

                  <v-col class="" md="4" sm="12">
                    <TagAutoComplete type="contact" v-model="detail.tags" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="" md="3" sm="12">
                    <label
                      class="font-size-16 font-weight-500"
                      for="display_name"
                      >Description
                    </label>
                  </v-col>

                  <v-col class="" md="9" sm="12">
                    <TextAreaField
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      v-model.trim="detail.description"
                      placeholder="Description"
                      solo
                      flat
                      row-height="25"
                      counter="200"
                    />
                    <!-- <v-textarea
                      v-model="detail.description"
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      placeholder="description"
                      solo
                      flat
                      row-height="15"
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea> -->
                  </v-col>
                  <!-- <v-col md="12" class="text-right"
                    >{{
                      detail.supplier_remark
                        ? detail.supplier_remark.length
                        : 0
                    }}/200</v-col
                  > -->
                </v-row>
                <v-row v-if="false">
                  <CustomForm
                    v-if="!detail.id"
                    :module="'contact'"
                    :module-id="detail.id"
                    @updated="getFieldCustomDate"
                  />
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </v-col>
          <v-col
            class="iv-custom-field"
            cols="3"
            md="3"
            sm="3"
            v-on:mouseleave="imageTemplate = false"
            v-on:mouseover="imageTemplate = true"
          >
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage ? getProfileImage : $defaultProfileImage"
                max-height="200"
                max-width="200"
                height="200px"
                width="200px"
                class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
              >
              </v-img>
              <div class="margin-auto">
                <v-btn
                  class="my-4 custom-bold-button width-100"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template v-if="getProfileImage">Change Image</template>
                  <template v-else>Upload Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-layout>
    </v-col>
  </v-layout>
</template>

<script>
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import SupplierMixin from "@/core/plugins/supplier-mixin";
import { GET, QUERY } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";
import ManageCustomerdesignation from "@/view/pages/partials/Manage-Customerdesignation.vue";
import ManageCompanydepartment from "@/view/pages/partials/Manage-Companydepartment.vue";
export default {
  name: "contact-details",
  title: "Contact",
  mixins: [CommonMixin, ValidationMixin, SupplierMixin, FileManagerMixin],
  props: {
    contact: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email_key: Number(new Date()),
      manageDesignationDialog: false,
      manageDepartmentDialog: false,
      designations: [],
      contactDetails: [],
      departments: [],
      groupList: [],
      pageLoading: false,
      lead: 0,
      proposal: 0,
      timeoutLimit: 500,
      timeout: null,
      autocompleteList: new Array(),
      autocompleteLoading: false,
      isFocused: false,
      isFocusedU: false,
      isFocusedN: false,
      isFocusedD: false,
      suggestedItems: [],
      suggestedItems2: [],
      suggestedItems3: [],
      suggestedItems4: [],
      suggestedItems5: [],
      suggestedItems6: [],

      detail: {
        company_email: null,
        company_number: null,
        did: null,
        id: null,
        primary_phone: null,
        hp_number: null,
        company_fax: null,
        description: null,
        primary: false,
        category: null,
        category_color: null,
        tags: [],
        title: "mr",
        first_name: null,
        last_name: null,
        company_id: null,
        display_name: null,
        profile_logo: null,
        type: "create",
        primary_email: null,
        phone: null,
        designation: null,
        will_notified: 1,
        default: 0,
        department: null,
      },
      imageLoading: false,

      companySelect: [],

      imageTemplate: false,
    };
  },
  watch: {
    perfectScrollHeight(param) {
      console.log({ param });
    },
    // "detail.primary"() {
    //   /*  if (this.detail.primary) { */
    //   this.detail.display_name = this.detail.company_name;
    //   /* } */
    // },
    // "detail.company_name"() {
    //   /*  if (this.detail.primary) { */
    //   /*   alert(32) */
    //   this.detail.display_name = this.detail.company_name;
    //   /*   } */
    // },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveContactDetail", param);
      },
    },
    emailDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
    contactDetails: {
      deep: true,
      handler() {
        this.updateDetails();
      },
    },
  },
  methods: {
    getTags() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer/options" })
        .then(({ data }) => {
          _this.options.all_tags = data.all_tags;
        })
        .catch((error) => {
          _this.logError(error);
        });
      /*.finally(() => {
          _this.$refs.companyForm.validate();
        });*/
    },
    setdepartment() {
      let _this = this;
      _this.$store
        .dispatch(GET, {
          url: "get-customer-department",
        })
        .then(({ data }) => {
          _this.departments = this.lodash.map(
            data.customer_department,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setOptions() {
      let _this = this;
      _this.$store
        .dispatch(GET, {
          url: "get-customer-designation",
        })
        .then(({ data }) => {
          _this.designations = this.lodash.map(
            data.customer_designation,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    allCompany() {
      let _this = this;
      _this.$store
        .dispatch(GET, {
          url: "customer-list",
        })
        .then(({ data }) => {
          _this.companySelect = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getFieldCustomDate($event) {
      console.log($event);
    },
    clearAddress() {
      const _this = this;
      _this.detail.postal_code = null;
      _this.detail.address_line_1 = null;
      _this.detail.address_line_2 = null;
      _this.detail.unit_number = null;
    },
    appendDisplayName() {
      const result = [];
      const { first_name, last_name } = this.detail;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }

      this.detail.display_name = result.join(" ");
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.detail.supplier_remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.detail.supplier_remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.detail.supplier_remark &&
        this.detail.supplier_remark.length > 199
      ) {
        e.preventDefault();
      }
    },

    company_rules() {
      const rules = [];

      rules.push(
        this.validateRules.required(this.detail.company_name, "Company name")
      );

      rules.push(
        this.validateRules.minLength(
          this.detail.company_name,
          "display name",
          2
        )
      );

      rules.push(
        this.validateRules.maxLength(
          this.detail.company_name,
          "display name",
          100
        )
      );

      return rules;
    },

    getCustomerList(search_key) {
      this.detail.display_name = search_key;
      this.suggestedItems = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "company_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerListDisplay(search_key) {
      this.suggestedItems2 = [];
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "search-company",
          data: {
            type: "customers",
            name: "display_name",
            value: search_key ? search_key : "null",
          },
        })
        .then((data) => {
          this.suggestedItems2 = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.detail.postal_code = result.zip_code;
              _this.detail.address_line_1 = result.street_1;
              _this.detail.address_line_2 = result.street_2;
              _this.detail.unit_number = result.unit_no;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.detail.postal_code && _this.detail.postal_code.length == 6) {
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.detail.postal_code },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        } else {
          _this.autocompleteList = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateDetails() {
      const _this = this;
      _this.detail.details = [];
      for (let i = 0; i < _this.emailDetails.length; i++) {
        if (_this.emailDetails[i]) {
          delete _this.emailDetails[i].default;
          _this.detail.details.push(_this.emailDetails[i]);
        }
      }
      for (let z = 0; z < _this.contactDetails.length; z++) {
        if (_this.emailDetails[z]) {
          delete _this.emailDetails[z].default;
          _this.detail.details.push(_this.contactDetails[z]);
        }
      }
    },
    pushEmailDetail() {
      this.emailDetails.push({
        title: "main",
        value: null,
        type: 1,
        id: null,
      });
    },
    removeEmailDetail(index) {
      this.emailDetails.splice(index, 1);
    },
    pushContactDetail() {
      this.contactDetails.push({
        title: "main",
        value: null,
        type: 2,
        id: null,
      });
    },
    removeContactDetail(index) {
      this.contactDetails.splice(index, 1);
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.detail.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    // getGroupList() {
    //   const _this = this;
    //   _this.$store
    //     .dispatch(GET, {
    //       url: "contact/group-list",
    //     })
    //     .then(({ data }) => {
    //       _this.groupList = data.group_list;
    //       _this.detail.security_code = data.security_code;
    //     })
    //     .catch((error) => {
    //       _this.logError(error);
    //     });
    // },
    editDetails() {
      const _this = this;

      //console.log(_this.contact,"_this.contact");

      _this.defaultPerson = _this.getDefaultPerson(_this.contact);
      // console.log(_this.defaultPerson, " _this.defaultPerson");
      _this.emailDetails = _this.getEmailDetails(_this.contact);
      // console.log( _this.emailDetails," _this.emailDetails");
      _this.contactDetails = _this.getPhoneDetails(_this.contact);

      _this.detail.primary = _this.contact.primary;

      this.emailDetails.push({
        title: "main",
        value: _this.contact.company_email,
        type: 1,
        id: null,
      });
      this.contactDetails.push({
        title: "main",
        value: _this.contact.company_number,
        type: 2,
        id: null,
      });

      //  _this.detail.salutation = _this.defaultPerson.title;
      // _this.detail.first_name = _this.defaultPerson.first_name;
      // _this.detail.last_name = _this.defaultPerson.last_name;

      _this.detail.id = _this.contact.id;
      _this.detail.website = _this.contact.website;
      _this.detail.company_id = _this.contact.customer.id;
      _this.detail.company_fax = _this.contact.company_fax;
      _this.detail.first_name = _this.contact.first_name;
      _this.detail.last_name = _this.contact.last_name;
      _this.detail.type = "create";

      _this.detail.primary_phone = _this.contact.primary_phone;
      _this.detail.did = _this.contact.did;

      _this.detail.primary_email = _this.contact.primary_email;
      _this.detail.hp_number = _this.contact.hp_number;
      _this.detail.designation = _this.contact.designation;
      _this.detail.department = _this.contact.department;
      _this.detail.description = _this.contact.description;

      _this.detail.tags = _this.contact.tags;
      _this.detail.category = _this.contact.category;
      _this.detail.category_color = _this.contact.category_color;
      _this.detail.uen = _this.contact.uen;
      _this.detail.company_name = _this.contact.company_name;
      _this.detail.display_name = _this.contact.display_name;
      _this.detail.group_id = _this.contact.group_id;
      _this.detail.phone = _this.contact.phone;
      _this.detail.website = _this.contact.website;
      _this.detail.unit_number = _this.contact.unit_number;
      _this.detail.address_line_1 = _this.contact.address_line_1;
      _this.detail.address_line_2 = _this.contact.address_line_2;
      _this.detail.postal_code = _this.contact.postal_code;
      _this.detail.country = _this.contact.country;
      _this.detail.state = _this.contact.state;
      _this.detail.city = _this.contact.city;
      _this.detail.profile_logo = _this.contact.profile_logo;
    },
    getLead() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getProposal() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "proposals/" + _this.proposal,
        })
        .then(({ data }) => {
          let fullname = data.name ? data.name.split(" ") : [];
          _this.detail.first_name = fullname[0];
          _this.detail.last_name = fullname[1];
          _this.detail.company_name = data.company;
          _this.detail.display_name = data.company;
          _this.detail.phone = data.phone_number;
          _this.detail.website = data.website;
          _this.detail.postal_code = data.zip;
          _this.detail.country = data.country;
          _this.detail.state = data.state;
          _this.detail.city = data.city;
          _this.detail.address_line_1 = data.address;

          _this.emailDetails[0] = new Object({
            title: "main",
            value: data.email,
            type: 1,
            id: null,
          });

          _this.contactDetails[0] = new Object({
            title: "main",
            value: data.phone_number,
            type: 2,
            id: null,
          });

          _this.updateDetails();
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    this.setOptions();
    this.setdepartment();
    this.getTags();

    this.allCompany();
    // _this.getGroupList();
    if (_this.lodash.isEmpty(_this.contact) === false) {
      _this.editDetails();
    }

    _this.lead = _this.lodash.toSafeInteger(_this.$route.query.lead);
    if (_this.lead > 0) {
      _this.getLead();
    }

    _this.proposal = _this.lodash.toSafeInteger(_this.$route.query.proposal);
    if (_this.proposal > 0) {
      _this.getProposal();
    }
  },
  components: {
    CustomForm,
    PhoneTextField,
    TextValidateInput,
    TagAutoComplete,
    ManageCustomerdesignation,
    ManageCompanydepartment,
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.detail.profile_logo) === false
        ? this.detail.profile_logo.file.url
        : null;
    },
  },
};
</script>
